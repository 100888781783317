<template>
  <div class="profile-wrapper emb-card pa-4">
    <h4>Profile Information</h4>
    <div class="pt-4">
      <div class="proflie-field mb-4">
        <v-img
          v-if="!empty(profileData.profile_photo)"
          max-height="150"
          max-width="250"
          :src="host_web + 'storage' + profileData.profile_photo"
        ></v-img>
        <v-file-input
          v-model="image"
          :rules="rules"
          :hide-input="true"
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Pick an Photo"
          prepend-icon="mdi-camera"
        ></v-file-input>
        <v-btn
          v-if="!empty(image)"
          color="green darken-1"
          text
          @click="uploadProfilePhoto"
        >
          Update Photo
        </v-btn>
      </div>
      <div class="proflie-field mb-4">
        <label>Display Name :</label>
        <span>{{ profileData.user_name }}</span>
      </div>
      <div class="proflie-field mb-4">
        <label>Full Name :</label>
        <span>{{ profileData.full_name }}</span>
      </div>
      <div class="proflie-field mb-4">
        <label>Email :</label>
        <span>{{ profileData.email_address }}</span>
      </div>
      <div class="proflie-field mb-4">
        <label>Contact Number :</label>
        <span>{{ profileData.contact_number }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getProfile() {
      this.post("ecom/member/get-profile").then((response) => {
        this.profileData = response.data.data;
      });
    },
    async uploadProfilePhoto() {
      let im = "";
      await this.getBase64(this.image)
        .then((res) => {
          im = res;
        })
        .catch((e) => {
          console.log(e.message);
        });
      let f = {
        guest_id: this.$cookie.get("guest_id"),
        photo: im,
      };
      console.log(f);
      this.post("ecom/member/update-profile-photo", f)
        .then((response) => {
          if (response.data.status == true) {
            this.profileData = response.data.data;
          } else {
            this.showError(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  created() {
    this.getProfile();
  },
  data() {
    return {
      profileData: {
        user_name: "",
        full_name: "",
        email_address: "",
        contact_number: "",
        profile_photo: "",
      },
      image: null,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Photo size should be less than 2 MB!",
      ],
    };
  },
};
</script>